// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("/builds/ronyclau/buhp/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-home-tsx": () => import("/builds/ronyclau/buhp/src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-gallery-tsx": () => import("/builds/ronyclau/buhp/src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-video-tsx": () => import("/builds/ronyclau/buhp/src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */),
  "component---src-templates-listing-tsx": () => import("/builds/ronyclau/buhp/src/templates/listing.tsx" /* webpackChunkName: "component---src-templates-listing-tsx" */),
  "component---src-pages-404-tsx": () => import("/builds/ronyclau/buhp/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("/builds/ronyclau/buhp/src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/ronyclau/buhp/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/ronyclau/buhp/.cache/data.json")

